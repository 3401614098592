import { kml } from '@tmcw/togeojson';
import { XMLParser } from 'fast-xml-parser';

export const parseKml = (data: string) => {
    const xmlParser = new XMLParser();
    const parsedXml = xmlParser.parse(data);

    const parsedKml = kml(new DOMParser().parseFromString(data, 'text/xml'));

    if (!parsedKml || !parsedKml.features || parsedKml.features?.length === 0) {
        return undefined;
    }

    console.log(parsedXml);

    let timekeeper = 0;

    const creator = parsedXml.kml.Document.name;
    const name = 'Test';
    const points = parsedKml.features
        // @ts-ignore
        .filter((f) => f.geometry?.coordinates?.length > 0)
        // @ts-ignore
        .flatMap((f) => f.geometry.coordinates)
        .map((point: any) => {
            return {
                time: timekeeper++,
                lat: point[1],
                lon: point[0],
                ele: point[2],
                speed: 0
            };
        });

    return {
        creator,
        name,
        points
    };
};
