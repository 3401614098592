import SaveIcon from '@mui/icons-material/Save';
import { FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import { setTitle } from '../../tools/SetTitle';
import OmniBox from '../omnibox/OmniBox';
import HomeLauncher from './HomeLauncher';

const Home = (): React.ReactElement => {
    const theme = useTheme();

    const [name, setName] = useLocalStorageState<string>('name');
    const [nameTemp, setNameTemp] = useState('');

    useEffect(() => {
        setTitle('Home');
    }, []);

    if (!name || name.length === 0) {
        return (
            <Container maxWidth="md">
                <FormControl fullWidth sx={{ m: 1 }}>
                    <InputLabel htmlFor="name-input">What is your name?</InputLabel>
                    <OutlinedInput
                        id="name-input"
                        label="What is your name?"
                        fullWidth
                        value={nameTemp}
                        onChange={(e) => setNameTemp(e.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton onClick={() => setName(nameTemp)} edge="end">
                                    <SaveIcon />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </Container>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ paddingBottom: theme.spacing(2) }}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h3" component="h1" gutterBottom sx={{ color: theme.palette.text.primary }}>
                        Welcome to Makani, {name}!
                    </Typography>
                    <Typography variant="body1" gutterBottom paragraph sx={{ color: theme.palette.text.primary }}>
                        <b>What is Makani?</b> Makani is a set of tools used by <a href="https://start.limanovember.aero/">me</a> before and after a flight.
                    </Typography>
                    <Typography variant="body1" paragraph sx={{ color: theme.palette.text.primary }}>
                        <b>What does Makani mean?</b> Makani is a gender-neutral Hawaiian name meaning &quot;The Wind&quot;.
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: theme.spacing(3) }}>
                    <OmniBox />
                </Grid>
                <Grid item xs={12} sx={{ marginTop: theme.spacing(3) }}>
                    <HomeLauncher />
                </Grid>
            </Grid>
        </Container>
    );
};

export default Home;
