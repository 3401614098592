import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import CloseIcon from '@mui/icons-material/Close';
import HistoryIcon from '@mui/icons-material/History';
import SaveIcon from '@mui/icons-material/Save';
import { Badge, Box, Button, ButtonGroup, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Tooltip } from 'chart.js';
import { saveAs } from 'file-saver';
import L, { LatLngTuple } from 'leaflet';
import 'leaflet.fullscreen';
import 'leaflet.fullscreen/Control.FullScreen.css';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { CircleMarker, MapContainer, Polyline, Popup, useMap } from 'react-leaflet';
import MapBaseLayers from '../common/MapBaseLayers';

import { BaseBuilder, buildGPX } from 'gpx-builder';
const { Point, Segment, Track } = BaseBuilder.MODELS;

ChartJS.register(LinearScale, CategoryScale, PointElement, LineElement, BarElement, Tooltip, Legend);

const MapAdjuster = ({ track }: { track: any[] }) => {
    const map = useMap();

    useEffect(() => {
        const group = new L.FeatureGroup();

        map.eachLayer((layer) => {
            // @ts-ignore
            if (layer.getBounds || layer.getLatLng) {
                group.addLayer(layer);
            }
        });

        try {
            map.fitBounds(group.getBounds());
        } catch (e) {
            console.warn(e);
        }
    }, [track]);

    return null;
};

const TrackCleaner = ({
    open,
    onClose,
    track,
    trackCreator,
    setTrack,
    filename
}: {
    open: boolean;
    onClose: () => void;
    track: any;
    trackCreator: string;
    setTrack: (track: any) => void;
    filename: string;
}): React.ReactElement => {
    const theme = useTheme();

    const [cleanedTrack, setCleanedTrack] = useState(track);

    const [autoCleaned, setAutoCleaned] = useState({ ele: 0, speed: 0, removed: 0 });
    const [removed, setRemoved] = useState(0);

    const polylineTrackOriginal = useMemo(() => track?.points?.map((p: any) => [p.lat, p.lon]), [track]);
    const polylineTrack = useMemo(() => cleanedTrack?.points?.map((p: any) => [p.lat, p.lon]), [cleanedTrack]);

    const chartData = useMemo(() => {
        return {
            labels: Array.from({ length: track?.points?.length }, (x, i) => i),
            datasets: [
                {
                    type: 'line',
                    label: 'Speed',
                    data: cleanedTrack?.points?.map((p: any) => p.speed),
                    fill: false,
                    backgroundColor: 'rgb(0, 99, 132)',
                    borderColor: 'rgba(0, 99, 132, 0.8)',
                    yAxisID: 'y',
                    pointRadius: 0
                },
                {
                    type: 'line',
                    label: 'Elevation',
                    data: cleanedTrack?.points?.map((p: any) => p.ele),
                    fill: false,
                    backgroundColor: 'rgb(0, 0, 132)',
                    borderColor: 'rgba(0, 0, 132, 0.8)',
                    yAxisID: 'y1',
                    pointRadius: 0
                }
            ]
        };
    }, [cleanedTrack]);

    const removeBeforePoint = (index: number) => {
        const cleaned = JSON.parse(JSON.stringify(cleanedTrack));
        console.log('Removing everything before point', cleaned.points.at(index));
        const oldLength = cleaned.points.length;
        cleaned.points.splice(0, index);
        setCleanedTrack(cleaned);
        setRemoved(removed + (oldLength - cleaned.points.length));
    };

    const removeAfterPoint = (index: number) => {
        const cleaned = JSON.parse(JSON.stringify(cleanedTrack));
        console.log('Removing everything after point', cleaned.points.at(index));
        const oldLength = cleaned.points.length;
        cleaned.points.splice(index, cleaned.points.length - 1);
        setCleanedTrack(cleaned);
        setRemoved(removed + (oldLength - cleaned.points.length));
    };

    const removePoint = (index: number) => {
        const cleaned = JSON.parse(JSON.stringify(cleanedTrack));
        console.log('Removing point', cleaned.points.at(index));
        cleaned.points.splice(index, 1);
        setCleanedTrack(cleaned);
        setRemoved(removed + 1);
    };

    const revert = () => {
        setCleanedTrack(track);
        setAutoCleaned({ ele: 0, speed: 0, removed: 0 });
        setRemoved(0);
    };

    useEffect(() => {
        console.log('Updating track');
        revert();
    }, [track]);

    const clean = () => {
        const autoCleanedStats = { ele: 0, speed: 0, removed: 0 };

        const cleaned = JSON.parse(JSON.stringify(cleanedTrack));
        cleaned.points.forEach((element: any, index: number) => {
            if (!element.ele || element.ele === 0 || element.ele === '') {
                element.ele = cleaned.points.at(index - 1).ele;
                console.log('Cleaning empty elevation', element);
                autoCleanedStats.ele++;
            }

            if (!element.speed || element.speed === 0 || element.speed === '') {
                element.speed = cleaned.points.at(index - 1).speed;
                console.log('Cleaning empty speed', element);
                autoCleanedStats.speed++;
            }

            if (index > 0) {
                let prevPoint = cleaned.points.at(index - 1);
                if (prevPoint.remove) {
                    let i = index - 1;
                    while (prevPoint.remove && --i > 0) {
                        prevPoint = cleaned.points.at(i);
                    }
                }

                if (prevPoint.lat === element.lat && prevPoint.lon === element.lon) {
                    if ((prevPoint.ele - element.ele) / prevPoint.ele > 0.5) {
                        element.ele = prevPoint.ele;
                        console.log('Cleaning elevation', element);
                        autoCleanedStats.ele++;
                    }

                    if ((prevPoint.speed - element.speed) / prevPoint.speed > 0.5) {
                        element.speed = prevPoint.speed;
                        console.log('Cleaning speed', element);
                        autoCleanedStats.speed++;
                    }
                }

                if ((prevPoint.speed - element.speed) / prevPoint.speed > 0.85) {
                    element.remove = true;
                    console.log('Removing element due to illogical speed', element);
                    autoCleanedStats.removed++;
                } else if ((prevPoint.ele - element.ele) / prevPoint.ele > 0.85) {
                    element.remove = true;
                    console.log('Removing element due to illogical elevation', element);
                    autoCleanedStats.removed++;
                }
            }
        });

        cleaned.points = cleaned.points.filter((p: any) => !p.remove);

        setCleanedTrack(cleaned);
        setAutoCleaned(autoCleanedStats);
    };

    const save = () => {
        const pts = cleanedTrack.points.map((p: any) => new Point(p.lat, p.lon, { ele: p.ele, time: new Date(p.time), magvar: p.speed }));
        const gpxExport = new BaseBuilder();
        gpxExport.setTracks([new Track([new Segment(pts)], { name: track.name })]);
        const gpxData = buildGPX(gpxExport.toObject()).replaceAll('magvar', 'speed').replace('fabulator:gpx-builder', trackCreator); // Fulhack

        setTrack(cleanedTrack);

        const blob = new Blob([gpxData], { type: 'text/xml' });
        saveAs(blob, filename.replace('.json', '.gpx'));
    };

    const anyChanges = autoCleaned.ele > 0 || autoCleaned.speed > 0 || autoCleaned.removed > 0 || removed > 0;

    return (
        <Dialog open={open} fullWidth maxWidth={false} onClose={onClose}>
            <DialogTitle>
                SkyDemon Track Cleaner
                <IconButton style={{ float: 'right' }} onClick={() => onClose()} size="small">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {polylineTrack?.length > 0 && (
                    <Box style={{ height: 850, maxWidth: '100%' }}>
                        <Box style={{ height: 550 }}>
                            <MapContainer
                                doubleClickZoom={false}
                                scrollWheelZoom={true}
                                style={{ height: '100%' }}
                                // @ts-ignore
                                fullscreenControl={true}
                                // @ts-ignore
                                fullscreenControlOptions={{ position: 'topleft' }}>
                                <MapBaseLayers />

                                <Polyline
                                    positions={polylineTrackOriginal}
                                    pathOptions={{
                                        color: 'gray',
                                        weight: 5
                                    }}
                                />

                                <Polyline
                                    positions={polylineTrack}
                                    pathOptions={{
                                        color: 'magenta',
                                        weight: 3
                                    }}
                                />

                                {cleanedTrack?.points?.map((point: any, index: number) => (
                                    <CircleMarker
                                        key={index}
                                        center={[point.lat, point.lon] as LatLngTuple}
                                        radius={3}
                                        pathOptions={{ color: 'magenta', fill: true, fillColor: 'white' }}
                                        eventHandlers={{
                                            dblclick: () => {
                                                removePoint(index);
                                            }
                                        }}>
                                        <Popup>
                                            <ButtonGroup orientation="vertical" variant="outlined">
                                                <Button onClick={() => removeBeforePoint(index)}>Remove everything before</Button>
                                                <Button onClick={() => removeAfterPoint(index)}>Remove everything after</Button>
                                            </ButtonGroup>
                                        </Popup>
                                    </CircleMarker>
                                ))}

                                <MapAdjuster track={track} />
                            </MapContainer>
                        </Box>
                        <Line
                            // @ts-ignore
                            type="line"
                            // @ts-ignore
                            data={chartData}
                            style={{ maxHeight: 300 }}
                            options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                normalized: true,
                                scales: {
                                    y: {
                                        title: {
                                            display: true,
                                            text: 'Ground speed'
                                        },
                                        // @ts-ignore
                                        type: 'linear',
                                        display: true,
                                        position: 'left'
                                    },
                                    y1: {
                                        title: {
                                            display: true,
                                            text: 'GPS elevation'
                                        },
                                        // @ts-ignore
                                        type: 'linear',
                                        display: true,
                                        position: 'right',
                                        // @ts-ignore
                                        gridLines: {
                                            drawOnArea: false
                                        }
                                    },
                                    // @ts-ignore
                                    x: { display: false, gridLines: { drawOnArea: false } }
                                }
                            }}
                        />
                    </Box>
                )}
            </DialogContent>
            <DialogActions sx={{ paddingRight: theme.spacing(3), paddingBottom: theme.spacing(2) }}>
                {anyChanges && (
                    <Stack direction="row" spacing={theme.spacing(3)} flex="1" paddingLeft={theme.spacing(3)}>
                        <Typography>Changes:</Typography>
                        {autoCleaned.ele > 0 && (
                            <Badge badgeContent={autoCleaned.ele} showZero color="info">
                                <Chip label="Elevation (AutoClean)" />
                            </Badge>
                        )}
                        {autoCleaned.speed > 0 && (
                            <Badge badgeContent={autoCleaned.speed} showZero color="info">
                                <Chip label="Speed (AutoClean)" />
                            </Badge>
                        )}
                        {autoCleaned.removed > 0 && (
                            <Badge badgeContent={autoCleaned.removed} showZero color="error">
                                <Chip label="Removed (AutoClean)" />
                            </Badge>
                        )}
                        {removed > 0 && (
                            <Badge badgeContent={removed} showZero color="error">
                                <Chip label="Removed (Manual)" />
                            </Badge>
                        )}
                    </Stack>
                )}
                <Button variant="outlined" size="large" color="secondary" startIcon={<HistoryIcon />} onClick={revert} disabled={!anyChanges}>
                    Revert changes
                </Button>
                <Button variant="outlined" size="large" startIcon={<CleaningServicesIcon />} onClick={clean}>
                    AutoClean
                </Button>
                <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    startIcon={<SaveIcon />}
                    onClick={save}
                    disabled={!anyChanges && !filename.toLowerCase().includes('.json')}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TrackCleaner;
