import dayjs from 'dayjs';
import { convertKtsToMs } from './Conversions';

export const parseCloudAhoy = (data: string) => {
    const parsedCA = JSON.parse(data);

    if (!parsedCA || !parsedCA.flt || parsedCA.flt?.points?.length === 0) {
        return undefined;
    }

    let timekeeper = dayjs.unix(parsedCA.flt.u.at(0).I.start);

    const airports = parsedCA.flt.u.map((x: any) => x.I?.e?.attr?.airportICAO).filter((x: any) => !!x);

    const creator = 'CloudAhoy';
    const name = `${timekeeper.format('YYYY-MM-DD')} - ${airports.join(' -> ')}`;
    const points = parsedCA.flt?.points.map((point: any) => {
        timekeeper = timekeeper.add(1, 'second');
        return {
            time: timekeeper.toISOString(),
            lat: point[1],
            lon: point[0],
            ele: point[2],
            speed: convertKtsToMs(point[3])
        };
    });

    return {
        creator,
        name,
        points,
        airports
    };
};
