// eslint-disable react/react-in-jsx-scope
import Button, { ButtonProps } from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import React from 'react';

const ActionButton: React.FC<ButtonProps> = (props) => {
    const theme = useTheme();

    // @ts-ignore
    return (
        <Button variant="contained" sx={{ margin: theme.spacing(1) }} {...props}>
            {
                // @ts-ignore
                props.children
            }
        </Button>
    );
};

export default ActionButton;
